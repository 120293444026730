header
    width: 100%
    right: 0
    left: 0
    top: 0
    position: absolute
    top: 0
    right: 0
    left: 0
    z-index: 100
    transition: box-shadow 0.5s, background 0.5s
    
    .center
        height: auto
        display: flex
        justify-content: space-between
        align-items: center
        padding: 1rem
        min-height: 0
    .logo
        background: url(./images/logo.svg) no-repeat left center
        background-size: contain
        width: 260px
        height: 78px
        display: block
        text-indent: -999em
    .main_menu
        display: flex
        flex: 1
        align-items: center
        justify-content: flex-end
        display: block
        @media all and (min-width: 902px)
            display: block!important
        @media all and (max-width: 901px)
            display: none
            position: fixed
            left: 0
            top: 0
            right: 0
            bottom: 0
            width: 100%
            height: 100%
            overflow: auto
            -webkit-overflow-scrolling: touch
            padding: 2rem 1rem
            background: var(--color__a)
            height: 100%!important
            ul
                display: block
    ul
        list-style-type: none
        display: flex
        align-items: center
        margin: 0
        padding: 0
        li a
            text-decoration: none
            display: block
            padding: 0.5rem
            color: var(--color__b)
            &:hover
                color: var(--color__2)
    &.scrolling
        position: fixed
        z-index: 1000
        background: var(--color__a)
        box-shadow: 0 5px 20px rgba(0,0,0,0.1)
        .logo
            width: 220px
            height: 50px
            display: block

.mobile.nav
    background: var(--color__2)
    display: flex
    width: 50px
    height: 50px
    align-items: center
    justify-content: center
    position: absolute
    right: 0
    top: 20px
    cursor: pointer
    
    .burger
        width: 20px
        height: 20px
        display: flex
        align-items: flex-end
        flex-direction: column
        user-select: none
        span
            display: block
            background-color: var(--color__3)
            height: 2px
            margin-bottom: 6px
            flex-shrink: 0
            width: 100%
            transition: width 0.3s
    &:hover
        .burger
            span
                width: 100%
@media all and (min-width: 901px)
    .mobile.nav
        display: none!important