@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,400;0,700;1,700&family=Roboto&display=swap')
@import './css/vars.sss'
@import './css/layout.sss'
@import './css/components.sss'
@import './css/helpers.sss'


::selection
    background: var(--color__b)
    color: var(--color__a)
::-moz-selection
    background: var(--color__b)
    color: var(--color__a)

body, html
    padding: 0
    margin: 0
    overflow-x: hidden
    height: 100%

*, body
    font-family: 'Roboto', sans-serif
    box-sizing: border-box
    font-size: var(--fsize)px
    word-wrap: break-word
    color: var(--color__b)
    
h1, h2, h3, h4, th
    font-family: 'Aleo', serif
    padding: 0
    letter-spacing: 0.03em
    margin: 0
    padding-bottom: 30px
    font-weight: 900
    line-height: 0.8em
    overflow-wrap: break-word
    word-wrap: break-word
    color: var(--color__b)
    hyphens: auto
h1
    font-size: calc(100/var(--fsize))rem
    font-weight: 900
    @media all and (max-width: 600px)
        font-size: 1.8rem
h2
    font-size: calc(30/var(--fsize))rem

h3
    font-size: calc(25/var(--fsize))rem

h4, th
    font-size: calc(20/var(--fsize))rem

h5
    font-size: calc(18/var(--fsize))rem

sub 
    font-size: 0.6rem
    position: relative

sup
    font-size: 0.6rem
    position: relative


span, strong, em
    font-size: calc(16/var(--fsize))rem
a, .read_more_link
    color: var(--color__1)
    cursor: pointer
    text-decoration: underline
    transition: opacity 0.3s, transform 0.3s
    font-size: calc(16/var(--fsize))rem
    &:hover
        opacity: 0.8
        transform: scale(1.02)

ul, ol
    color: var(--color__b)
    margin-top: 0
    margin-left: 1rem
    padding-left: 1rem
    padding-bottom: 1rem
    li
        font-size: calc(16/var(--fsize))rem
p
    padding: 0 
    margin: 0
    padding-bottom: 20px
    font-size: calc(16/var(--fsize))rem
    line-height: 1.8em

p em
    font-size: calc(16/var(--fsize))rem
p strong
    font-weight: 600
p, em, i, span, strong
    color: var(--color__b)
dd, dt
    padding: 0
    margin: 0
table
    border-collapse: collapse
    margin-bottom: 10px
    td, th
        padding: 3px
        font-size: calc(16/var(--fsize))rem
    td:nth-child(2)
        min-width: 80px
    
form
    
    fieldset
        border: none
        margin: 0
        padding: 0

    label
        font-size: 0.8rem
        color: var(--color__b)
        padding: 1rem 0
        display: block
        &.show
            display: inline
    .row
        padding: 0.2rem 0
    input, textarea
        font-size: 0.8rem
        width: 100%
        border: solid 1px var(--color__1)
        padding: 0.5rem
        color: var(--color__b)
    input[type=checkbox]
        width: auto
        display: inline-block
    input[type=submit]
        width: auto
        background-color: var(--color__2)
        min-width: 200px
        padding: 1rem 2rem
        border: none
        cursor: pointer
        color: var(--color__a)
        font-size: 1.5rem

@media all and (max-width: 800px)
    iframe
        width: 100%!important
    
footer
    background: var(--color__3)
    ul
        list-style-type: none
        padding: 2rem
        margin: 0
        display: flex
        justify-content: flex-end
        flex-direction: column
        li
            padding-bottom: 0.3rem
        a
            text-decoration: none
            color: var(--color__4)
            text-transform: uppercase
            font-size: 0.8rem
    .coll.content
        display: flex
        align-items: flex-end
        justify-content: flex-end
        padding: 2rem
        img
            margin: 5px
            width: 30%
            height: auto
            float: left