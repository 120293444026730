.row_start
    h1, p
        text-align: center
    .center
        max-width: 850px
        padding-top: 6rem
    position: relative
    .inner_content
        padding-bottom: 2rem
        @media all and (max-width: 600px)
            padding: 1rem

    &:before
        content: ''
        background: var(--color__4) url(./images/balloon.png) no-repeat 90% 30%
        background-size: 250px 230px
        position: absolute
        left: 0
        top: 0
        right: 0
        height: 80%
    &.small:before
        background-size: 150px 130px
        height: 100%
    @media all and (max-width: 600px)
        &:before
            background: var(--color__4)

.cards
    display: flex
    @media all and (max-width: 600px)
        flex-direction: column
    .card
        background: var(--color__a)
        margin: 1rem
        min-width: 320px
        padding: 2rem
        flex: 1 1 0
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        box-shadow: 0 5px 20px rgba(0,0,0,0.1)
    .btns
        align-self: flex-end
        margin-top: auto
        padding: 0
        width: 100%
        justify-content: center
        align-items: center
    .btns .btn
        width: 100%
        text-align: center!important
    p
        font-family: 'Aleo', serif
        font-weight: 800
        font-size: 2rem
.row_content
    .coll
        padding: 2rem
    .coll.img
        &:before
            content: ''
            display: block
            background: url(./images/test_office.png) no-repeat center right
            background-size: contain
            width: 100%
            height: 260px

.reviews
    .center
        max-width: 800px
    .left
        display: flex
        flex-direction: column
        justify-content: flex-end
        text-align: right