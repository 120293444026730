.content_align_left
    max-width: calc(1200 / 2)
    margin-right: auto
.hidden
    display: none!important
.center
    margin: 0 auto
    position: relative
    width: 100%
    max-width: 1200px
    min-height: 150px
    margin: 0 auto
    @media all and (max-width: 600px)
        padding: 0 1rem
    &.middle
        max-width: 800px
        padding: 0 1rem
    &.small
        max-width: 800px

    @media all and (max-width: 600px)
        position: relative
        width: 100%
        margin: 0
        transform: none
.centered_content
    max-width: 700px
    margin: 0 auto
    &.page
        padding-bottom: 0!important
    &.page_content
        text-align: left!important
    @media all and (max-width: 600px)
        width: auto
.no_title
    display: none
.colls
    display: flex
    @media all and (max-width: 600px)
        display: block

.coll
    width: 50%
    text-align: left


    & .center
        text-align: center

    @media all and (max-width: 600px)
        width: 100%
.back_btn
    font-weight: 800
    font-size: 1rem
    padding: 1rem 0
    display: block
    color: var(--color__1)
    &:before
        color: var(--color__1)

.title
    font-size: 3rem
    font-weight: 900
    line-height: 1.1em
    @media all and (max-width: 600px)
        font-size: 2rem
.title_x2
    font-size: 3.2rem
    @media all and (max-width: 600px)
        font-size: 2rem
.title_x3
    font-size: 2.4rem
    @media all and (max-width: 600px)
        font-size: 1.8rem
.title_x4
    font-size: 2rem
    @media all and (max-width: 600px)
        font-size: 1.8rem
.title_x5
    font-size: 1.5rem
.subtitle
    font-size: 1.8rem
    color: var(--color__1)
    font-weight: 200
    line-height: 1.1em
.pt-5
    padding-top: 0.5rem
.pt1
    padding-top: 1rem
.pt2
    padding-top: 2rem
.pt3
    padding-top: 3rem
.pt4
    padding-top: 4rem
.pt5
    padding-top: 5rem
.pt6
    padding-top: 6rem
.pb2
    padding-bottom: 2rem
.pb3
    padding-bottom: 3rem
.pb1
    padding-bottom: 1rem
.pb4
    padding-bottom: 4rem
.pb5
    padding-bottom: 5rem
.pb6
    padding-bottom: 6rem
.pb-5
    padding-bottom: 0.5rem
.pl1
    padding-left: 1rem
.pl2
    padding-left: 2rem
.pr1
    padding-right: 1rem
.pr2
    padding-right: 2rem
.center_txt
    text-align: center
.btns
    display: flex
    padding: 1rem 0
    .btn
        font-weight: 800
        text-decoration: none
        font-size: 1.5rem
        color: var(--color__a)
        background: var(--color__1)
        display: inline-flex
        padding: 1rem 2rem
        justify-content: center
        align-items: center
        &.success
            background: var(--color__2)
        &.light
            color: var(--color__a)
        &:hover:after
            transform: scaleX(1.2)

.divider
    display: block
    border-left: solid 1px var(--color__a)
    margin: 0 1rem
            
a.inline_btn
    display: block
    text-align: center
    color: var(--color__1)
    text-decoration: underline
    padding: 1rem 0
    font-weight: 800
    text-transform: uppercase

.nav.slider

    span.label
        display: block
        position: absolute
        right: 0
        top: 60px
        font-style: italic
        font-size: 1.1rem
        color: var(--color__a)

    .nav_btns
        span
            cursor: pointer
            color: var(--color__a)
            display: block
            width: 60px
            &:before, &:after
                transition: transform 0.3s
                display: block
                transform-origin: center left
            &:before
                font-size: 2rem
            &:after
                content: ''
                display: block
                height: 10px
                background-color: var(--color__2)
                margin-top: 0.2rem
            &:hover:before
                transform: translateX(5px)
            &:hover:after
                transform: scaleX(0.8)
.group_title
    padding: 1rem
    display: flex
    align-items: center
    .img
        background-size: contain
        background-position: center center
        width: 100px
        height: 50px
        margin-right: 0.5rem
        background-repeat: no-repeat
        img
            display: none
.border_separator
    border-bottom: solid 1px var(--color__a)
.dark_skin
    background: var(--color__3)!important
.light_skin
    background: var(--color__a)!important

.content.flex
    display: flex
    width: 50%
    padding-right: 1rem
    @media all and (max-width: 600px)
        display: block
        width: 100%
        padding-right: 0

.bg_1
    background-color: var(--color__4)
.bg_2
    background-color: var(--color__3)
.mh_1
    min-height: 850px
.mh_2
    min-height: 450px


.flex_center
    display: flex
    justify-content: center
    align-items: center
    flex: 1
.flex-vert
    flex-direction: row
.flex-hot
    flex-direction: column

.clock_img
    height: 320px
    width: 100%
    background: url(./images/stopclock.png) no-repeat center center
    display: block

.inline_list, .pills
    padding: 2rem 0
    margin: 0
    display: flex
    flex-wrap: wrap
    list-style-type: none
    li
        padding: 0 0.5rem 0.5rem
        list-style-type: none
        a
            font-family: 'Aleo', serif
            font-weight: 800

.img
    width: 150px
    height: 150px
    border-radius: 100%
    background-position: center center
    background-size: cover
    display: block
    img
        display: none

ul.errors
    li
        color: var(--color__6)
.light_blue
    background-color: var(--color__4)

.w_2
    width: 20%
.w_3
    width: 30%
.w_4
    width: 40%
.w_5
    width: 50%
.w_6
    width: 60%
.w_7
    width: 70%
.w_8
    width: 80%